/* eslint-disable jsx-a11y/label-has-associated-control */

'use client'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import braintree, { HostedFields } from 'braintree-web'

import {
  createBraintreeMembershipPayment,
  getBraintreeToken,
} from '../../../../store/duck/braintree'
import {
  // @ts-ignore
  getMembershipBillingAddress,
  getMembershipToken,
} from '../../../../store/duck/wellnessPlans'
import { BraintreePaymentData } from '../../../../types/entities/memberships'
import { BraintreeField } from '../../../inputs/BraintreeField/BraintreeField'

interface BraintreePaymentFormProps {
  readonly onFormComplete: () => void
}

export const BraintreePaymentForm = React.forwardRef<
  HTMLFormElement,
  BraintreePaymentFormProps
  // TODO: implement onFormComplete functionality
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ onFormComplete }: BraintreePaymentFormProps, ref) => {
  const dispatch = useDispatch()
  const [hostedFields, setHostedFields] = useState<HostedFields | null>(null)

  const braintreeToken = useSelector(getBraintreeToken)
  const membershipToken = useSelector(getMembershipToken)

  useEffect(() => {
    async function initializeBraintree() {
      try {
        const clientInstance = await braintree.client.create({
          authorization: braintreeToken,
        })

        const braintreeFields = await braintree.hostedFields.create({
          fields: {
            cardholderName: {
              container: '#cardholder_name',
            },
            number: {
              container: '#card_number',
            },
            expirationDate: {
              container: '#expiration_date',
              placeholder: 'MM/YY',
            },
            cvv: {
              container: '#cvv',
            },
          },
          client: clientInstance,
        })
        setHostedFields(braintreeFields)
      } catch (err) {
        // TODO: Handle errors
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }
    initializeBraintree()
  }, [braintreeToken])

  const billingAddress = useSelector(getMembershipBillingAddress) || {}

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let braintreePaymentData: BraintreePaymentData = {
      extendedAddress: billingAddress.apartmentNumber,
      firstName: billingAddress.firstName,
      lastName: billingAddress.lastName,
      locality: billingAddress.city,
      postalCode: billingAddress.zip,
      region: billingAddress.country,
      streetAddress: billingAddress.street,
      brandCode: '',
      cardBin: '',
      cardLastFour: '',
      nonce: '',
    }
    if (hostedFields) {
      const braintreeData = await hostedFields.tokenize()
      braintreePaymentData = {
        ...braintreePaymentData,
        brandCode: braintreeData.details.cardType,
        cardBin: braintreeData.details.bin,
        cardLastFour: braintreeData.details.lastFour,
        nonce: braintreeData.nonce,
      }
    }
    dispatch(
      createBraintreeMembershipPayment({
        membershipToken,
        braintreePaymentData,
      }),
    )
  }

  return (
    <form ref={ref} onSubmit={handleSubmit}>
      <BraintreeField fieldId="cardholder_name" labelText="Cardholder Name" />
      <BraintreeField fieldId="card_number" labelText="Card Number" />
      <BraintreeField fieldId="cvv" labelText="CVV" />
      <BraintreeField fieldId="expiration_date" labelText="Expiration Date" />
    </form>
  )
})
