import React, { ReactNode } from 'react'
import { KibErrorFillIcon } from '@chewy/kib-icons-react'

import styles from './BraintreeField.module.scss'

interface BraintreeFieldProps {
  readonly errorMessage?: string
  readonly fieldControlClassNames?: string
  readonly fieldId: string
  readonly helpMessage?: string
  readonly helpMessagePosition?: 'top' | 'bottom'
  readonly icon?: ReactNode
  readonly labelText: string
}

export const BraintreeField = ({
  errorMessage,
  fieldControlClassNames,
  fieldId,
  helpMessage,
  helpMessagePosition = 'top',
  icon,
  labelText,
}: BraintreeFieldProps) => {
  const helpMessageClass = `${styles.helpMessage} ${
    helpMessagePosition === 'bottom' ? styles.helpMessageBottom : ''
  }`

  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor={fieldId}>
        {labelText}
      </label>
      {helpMessage && <span className={helpMessageClass}>{helpMessage}</span>}
      <div className={styles.field}>
        <div
          className={`${styles.control} ${fieldControlClassNames}`}
          id={fieldId}
        />
        {icon && <span className={styles.fieldIcon}>{icon}</span>}
      </div>
      <div className={styles.error}>
        {errorMessage && (
          <div className={styles.errorMessage}>
            <span className={styles.errorIcon}>
              <KibErrorFillIcon />
            </span>

            {errorMessage}
          </div>
        )}
      </div>
    </div>
  )
}
