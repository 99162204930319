import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { FEATURE_FLAGS, VARIANT } from '@chewy-payments-experience/constants'
import PaymentsProvider from '@chewy-payments-experience/payments-provider'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { PuiMomentUtils } from '@pbt/pbt-ui-components'

import ErrorWatcher from './components/ErrorWatcher'
import Main from './components/Main'
import ScrollToTop from './components/ScrollToTop'
import WindowVariableInjector from './components/WindowVariableInjector'
import { useChangeLanguage } from './utils/useChangeLanguage'

const App = () => {
  const { i18n } = useChangeLanguage()

  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={PuiMomentUtils}>
        <CssBaseline />
        <WindowVariableInjector />
        <ErrorWatcher />
        <ScrollToTop>
          <PaymentsProvider
            featureFlags={
              new Map([
                [
                  FEATURE_FLAGS.USE_KIB_FIELDS_NEW,
                  VARIANT.VARIANT_1, // set to VARIANT.CONTROL to show old designs
                ],
              ])
            }
          >
            <Main />
          </PaymentsProvider>
        </ScrollToTop>
      </LocalizationProvider>
    </I18nextProvider>
  )
}

export default App
