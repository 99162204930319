import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { mergeWithKey } from 'ramda'
import {
  Nil,
  NumberUtils,
  PuiTheme,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import Features from '../../../../constants/features'
import {
  MembershipSignUpFlow,
  WellnessPlanLevels,
} from '../../../../constants/wellnessPlansConstants'
import { getCurrentBusinessIsOmniChannel } from '../../../../store/duck/businesses'
import { getCurrentClient } from '../../../../store/duck/clients'
import { getFeatureToggle } from '../../../../store/duck/constants'
import {
  getMembershipIsPortalEmbed,
  getMembershipSelection,
  getMembershipToken,
  getWellnessPlansIsLoading,
  getWellnessPlansIsSelectionStoring,
  getWellnessPlansVersion,
  updateActiveSignUpFlow,
  updateMembershipSignUpTokenSelection,
  // @ts-ignore
} from '../../../../store/duck/wellnessPlans'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../../utils'
import useBoopEnabled from '../../../../utils/useBoopEnabled'
import useCallbackWhenLoaded from '../../../../utils/useCallbackWhenLoaded'
import KioskLinkButton from '../../../buttons/KioskLinkButton'
import KioskScreen from '../../KioskScreen'
import MembershipTotalsLabel from '../MembershipTotalsLabel'
import useHandleFreeSignUp from '../useHandleFreeSignUp'
import {
  getPlanByLevel,
  useHandleSendSignUpLink,
  useTotalsPerPatient,
  // @ts-ignore
} from '../wellnessPlanUtils'
// @ts-ignore
import MembershipSummaryTable from './MembershipSummaryTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    title: {
      textAlign: 'left',
      padding: theme.spacing(2, 4, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 1, 0),
      },
    },
    content: {
      padding: 0,
    },
    linksContainer: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    baseFeeText: {
      alignSelf: 'flex-start',
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    confirmButton: {
      fontSize: '1.4rem',
      width: 180,
    },
  }),
  { name: 'MembershipPlanSummaryScreen' },
)

const MembershipPlanSummaryScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Membership')
  const location = useLocation()

  const navigateWithQueryString = useNavigateWithQueryString()

  const client = useSelector(getCurrentClient)
  const token = useSelector(getMembershipToken)
  const selection = useSelector(getMembershipSelection)
  const isPortalEmbed = useSelector(getMembershipIsPortalEmbed)
  const isMembershipSelectionStoring: boolean | undefined = useSelector(
    getWellnessPlansIsSelectionStoring,
  )
  const isManualSignUpLoading: boolean | undefined = useSelector(
    getWellnessPlansIsLoading,
  )
  const wellnessPlansVersion: WellnessPlanVersion | Nil = useSelector(
    getWellnessPlansVersion,
  )
  const isTermsAndConditionsFeatureEnabled = useSelector(
    getFeatureToggle(Features.WP_TERMS_AND_CONDITIONS),
  )
  const isWpOneTimeFeeEnabled = useSelector(
    getFeatureToggle(Features.WP_ONE_TIME_FEE),
  )

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(Features.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )

  const showAddPet = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('sm'),
  )

  const handleSendSignUpLink = useHandleSendSignUpLink()

  const isHybridFlow = isPortalEmbed
  const allPlans = wellnessPlansVersion?.plans || []
  const baseLevelPlan = getPlanByLevel(WellnessPlanLevels.BASE, allPlans)
  const baseLevelPlanPrice = NumberUtils.formatMoney(baseLevelPlan?.price)
  const baseLevelPlanPriceType = baseLevelPlan?.priceType?.displayName || ''
  const memberships = client?.memberships || []
  const planOneTimeFeeAmount = NumberUtils.formatMoney(
    wellnessPlansVersion?.oneTimeFeeAmount,
  )
  const boopFlowEnabled = useBoopEnabled()

  const totalsPerPatient = useTotalsPerPatient(selection, memberships)

  const totals = Object.keys(totalsPerPatient).reduce((acc, patientId) => {
    const totalsForPatient = totalsPerPatient[patientId]
    return mergeWithKey(
      (key, left, right) => left + right,
      acc,
      totalsForPatient,
    )
  }, {})

  const { handler: handleFreeSignUp, isLoading: signUpIsLoading } =
    useHandleFreeSignUp()

  const searchParams = new URLSearchParams(location.search)
  searchParams.delete('view')

  const onSelectionUpdatedForManualFlow = () => {
    if (isTermsAndConditionsFeatureEnabled) {
      navigateWithQueryString({
        url: '/membership/terms-and-conditions',
        search: searchParams.toString()
          ? `?${searchParams.toString()}&view=false`
          : `?view=false`,
      })
    } else if (client?.isBoopUser) {
      handleFreeSignUp()
    } else {
      navigateWithQueryString({ url: '/membership/email-verification' })
    }
  }

  const setCallbackWhenSelectionUpdatedOn = useCallbackWhenLoaded(
    onSelectionUpdatedForManualFlow,
    getWellnessPlansIsLoading,
  )

  const onManualSignUp = () => {
    dispatch(updateActiveSignUpFlow(MembershipSignUpFlow.MANUAL))

    setCallbackWhenSelectionUpdatedOn()
    dispatch(updateMembershipSignUpTokenSelection(token, selection))
  }

  const onAddPatientRequested = () => {
    navigateWithQueryString({
      url: '/membership/patient-details',
      options: {
        replace: true,
        state: {
          redirectUrl: '/membership/plan-summary',
        },
      },
    })
  }

  const onViewTermsAndConditionsRequested = () => {
    navigateWithQueryString({
      url: '/membership/terms-and-conditions',
      search: location.search
        ? `?${searchParams.toString()}&view=true`
        : `?view=true`,
      options: {
        replace: true,
        state: {
          redirectUrl: '/membership/plan-summary',
        },
      },
    })
  }

  const kioskScreenHybridButtonProps = boopFlowEnabled
    ? {
        onSecondaryButtonClick: onManualSignUp,
        secondaryButtonLabel: 'Manual sign up',
        secondaryButtonDisabled:
          isManualSignUpLoading || isMembershipSelectionStoring,
        proceedButtonLabel: 'Send client a sign-up link',
        proceedButtonDisabled:
          isManualSignUpLoading || isMembershipSelectionStoring,
        proceedButtonLoading:
          isManualSignUpLoading ||
          signUpIsLoading ||
          isMembershipSelectionStoring,
        onProceed: handleSendSignUpLink,
      }
    : {
        proceedButtonLabel: 'Manual sign up',
        proceedButtonDisabled:
          isManualSignUpLoading || isMembershipSelectionStoring,
        proceedButtonLoading:
          isManualSignUpLoading ||
          signUpIsLoading ||
          isMembershipSelectionStoring,
        onProceed: onManualSignUp,
      }

  const kioskScreenButtonProps = isHybridFlow
    ? kioskScreenHybridButtonProps
    : {
        proceedButtonLabel: 'Continue',
        proceedButtonDisabled: signUpIsLoading || isManualSignUpLoading,
        proceedButtonLoading: signUpIsLoading || isManualSignUpLoading,
        onProceed: onManualSignUp,
      }

  return (
    <KioskScreen
      additionalLabel={
        <Grid item xs className={classes.linksContainer} sm="auto">
          {isMobile ? (
            <MembershipTotalsLabel alignItems="center" totals={totals} />
          ) : (
            <>
              {isPortalEmbed && isTermsAndConditionsFeatureEnabled && (
                <KioskLinkButton onClick={onViewTermsAndConditionsRequested}>
                  View terms and conditions
                </KioskLinkButton>
              )}
              {showAddPet && (
                <KioskLinkButton onClick={onAddPatientRequested}>
                  Add a pet
                </KioskLinkButton>
              )}
            </>
          )}
        </Grid>
      }
      classes={{
        content: classes.content,
        title: classes.title,
        confirmButton: classes.confirmButton,
      }}
      justifyContent="flex-start"
      title="Plan summary"
      {...kioskScreenButtonProps}
    >
      {baseLevelPlan?.price > 0 && (
        <Typography className={classes.baseFeeText}>
          {isWpOneTimeFeeEnabled && wellnessPlansVersion?.oneTimeFeeAmount
            ? t('Membership:EACH_SIGN_UP_HAS_BASE_AND_OTF_FEE_OF', {
                planPrice: `${baseLevelPlanPrice}/${baseLevelPlanPriceType}`,
                planOneTimeFeeAmount,
              })
            : t('Membership:EACH_SIGN_UP_HAS_BASE_FEE_OF', {
                planPrice: `${baseLevelPlanPrice}/${baseLevelPlanPriceType}`,
              })}
        </Typography>
      )}
      <MembershipSummaryTable
        totals={totals}
        totalsPerPatient={totalsPerPatient}
      />
    </KioskScreen>
  )
}

export default MembershipPlanSummaryScreen
